// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cpa-js": () => import("./../../../src/pages/cpa.js" /* webpackChunkName: "component---src-pages-cpa-js" */),
  "component---src-pages-curso-tecnico-ead-js": () => import("./../../../src/pages/curso-tecnico/ead.js" /* webpackChunkName: "component---src-pages-curso-tecnico-ead-js" */),
  "component---src-pages-curso-tecnico-presencial-js": () => import("./../../../src/pages/curso-tecnico/presencial.js" /* webpackChunkName: "component---src-pages-curso-tecnico-presencial-js" */),
  "component---src-pages-diplomas-js": () => import("./../../../src/pages/diplomas.js" /* webpackChunkName: "component---src-pages-diplomas-js" */),
  "component---src-pages-diplomas-registrados-js": () => import("./../../../src/pages/diplomas-registrados.js" /* webpackChunkName: "component---src-pages-diplomas-registrados-js" */),
  "component---src-pages-documentos-js": () => import("./../../../src/pages/documentos.js" /* webpackChunkName: "component---src-pages-documentos-js" */),
  "component---src-pages-ead-graduacao-js": () => import("./../../../src/pages/ead/graduacao.js" /* webpackChunkName: "component---src-pages-ead-graduacao-js" */),
  "component---src-pages-ead-pos-direito-js": () => import("./../../../src/pages/ead/pos-direito.js" /* webpackChunkName: "component---src-pages-ead-pos-direito-js" */),
  "component---src-pages-ead-pos-graduacao-js": () => import("./../../../src/pages/ead/pos-graduacao.js" /* webpackChunkName: "component---src-pages-ead-pos-graduacao-js" */),
  "component---src-pages-extensao-js": () => import("./../../../src/pages/extensao.js" /* webpackChunkName: "component---src-pages-extensao-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nae-js": () => import("./../../../src/pages/nae.js" /* webpackChunkName: "component---src-pages-nae-js" */),
  "component---src-pages-nordeste-sergipe-js": () => import("./../../../src/pages/nordeste/Sergipe.js" /* webpackChunkName: "component---src-pages-nordeste-sergipe-js" */),
  "component---src-pages-nossa-historia-js": () => import("./../../../src/pages/nossa-historia.js" /* webpackChunkName: "component---src-pages-nossa-historia-js" */),
  "component---src-pages-parceiros-js": () => import("./../../../src/pages/parceiros.js" /* webpackChunkName: "component---src-pages-parceiros-js" */),
  "component---src-pages-pesquisa-js": () => import("./../../../src/pages/pesquisa.js" /* webpackChunkName: "component---src-pages-pesquisa-js" */),
  "component---src-pages-polos-js": () => import("./../../../src/pages/polos.js" /* webpackChunkName: "component---src-pages-polos-js" */),
  "component---src-pages-presencial-all-lagarto-js": () => import("./../../../src/pages/presencial/all-lagarto.js" /* webpackChunkName: "component---src-pages-presencial-all-lagarto-js" */),
  "component---src-pages-presencial-graduacao-js": () => import("./../../../src/pages/presencial/graduacao.js" /* webpackChunkName: "component---src-pages-presencial-graduacao-js" */),
  "component---src-pages-presencial-pos-graduacao-js": () => import("./../../../src/pages/presencial/pos-graduacao.js" /* webpackChunkName: "component---src-pages-presencial-pos-graduacao-js" */),
  "component---src-pages-requisicoes-js": () => import("./../../../src/pages/requisicoes.js" /* webpackChunkName: "component---src-pages-requisicoes-js" */),
  "component---src-pages-segunda-graduacao-js": () => import("./../../../src/pages/segunda-graduacao.js" /* webpackChunkName: "component---src-pages-segunda-graduacao-js" */),
  "component---src-pages-semipresencial-graduacao-js": () => import("./../../../src/pages/semipresencial/graduacao.js" /* webpackChunkName: "component---src-pages-semipresencial-graduacao-js" */),
  "component---src-pages-semipresencial-pos-graduacao-js": () => import("./../../../src/pages/semipresencial/pos-graduacao.js" /* webpackChunkName: "component---src-pages-semipresencial-pos-graduacao-js" */),
  "component---src-pages-vestibular-js": () => import("./../../../src/pages/vestibular.js" /* webpackChunkName: "component---src-pages-vestibular-js" */),
  "component---src-templates-course-js": () => import("./../../../src/templates/course.js" /* webpackChunkName: "component---src-templates-course-js" */),
  "component---src-templates-polo-js": () => import("./../../../src/templates/polo.js" /* webpackChunkName: "component---src-templates-polo-js" */)
}

